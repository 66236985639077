const Projects = [
  {
    id: "melt-works",
    navUp: "skills",
    navDown: "custom-clothing",
    github: "https://github.com/rbalonek",
    title: "Client: Melt Works",
    website: "https://www.melt.works/",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1721528344/Portfolio%20Pics/Ted%20Fisher/Fine%20Custom%20Clothing/Melt_Logo_ncr9ct.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1721528204/Portfolio%20Pics/Ted%20Fisher/Fine%20Custom%20Clothing/Melt_Logo_Animation_Gif_prtbmd.gif",
    ],
    text: [
      "Client wanted unique project scrolling experience",
      "Also wanted custom effects throughout site depending on which project you are in",
      "We built this from scratch using React. VERY fun experience",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/react-original-wordmark.fadd9c95_fhawii.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512573/Portfolio%20Pics/git-original-wordmark.66e06c06_s7bavw.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1664052599/emailJS_vobsjw.webp",
    ],
  },
  {
    id: "custom-clothing",
    navUp: "melt-works",
    navDown: "fix-website",
    github: "https://github.com/rbalonek",
    title: "Client: Fine Custom Clothes",
    website: "https://fine-custom-clothes.netlify.app/",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1721525804/Portfolio%20Pics/Ted%20Fisher/Fine%20Custom%20Clothing/Ted_Fisher_LP_Cell_bfwgq3.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1721525808/Portfolio%20Pics/Ted%20Fisher/Fine%20Custom%20Clothing/Ted_Fisher_LP_sgzxnt.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1721525805/Portfolio%20Pics/Ted%20Fisher/Fine%20Custom%20Clothing/Ted_Fisher_Footer_xphtrc.png",
    ],
    text: [
      "Client wanted elegant and responsive landing page for his custom clothing business",
      "He wanted big bold white, purple and gold color scheme with custom contact form.",
      "Built from scratch using React.",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/react-original-wordmark.fadd9c95_fhawii.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512573/Portfolio%20Pics/git-original-wordmark.66e06c06_s7bavw.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1664052599/emailJS_vobsjw.webp",
    ],
  },
  {
    id: "fix-website",
    navUp: "custom-clothing",
    navDown: "happy-space",
    github: "https://github.com/rbalonek/IllFixYourWebsite",
    title: "IllFixYourWebsite.com",
    website:
      "https://www.illfixyourwebsite.com/",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1662863587/Portfolio%20Pics/illfixyourwebsite_yu0yft.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1662915975/Portfolio%20Pics/ill%20fix%20your%20website/Screen_Shot_2022-09-11_at_10.05.46_AM_gjiiec.png",
    ],
    text: [
      "I built this site in order to show before and after examples of some of my work",
      "I added Google Analytics, Facebook Pixels, and SEO to try and naturally grow my google ranking.",
      "I built the site in an afternoon using Tailwind CSS and EmailJS."
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/react-original-wordmark.fadd9c95_fhawii.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512573/Portfolio%20Pics/git-original-wordmark.66e06c06_s7bavw.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1664052516/tailwindcss_ujnx0t.jpg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1664052599/emailJS_vobsjw.webp",
    ],
  },
  {
    id: "happy-space",
    navUp: "fix-website",
    navDown: "trinity",
    github: "https://github.com/rbalonek/Happy-Space-App",
    title: "Happy Space",
    website: "https://apps.apple.com/us/app/happy-space/id1544516931",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1612209966/Portfolio%20Pics/Happy-space/InspirationScreen_m5vfie.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1612209967/Portfolio%20Pics/Happy-space/CatScreen_kllufq.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1612209968/Portfolio%20Pics/Happy-space/DadScreen_qpvmtk.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1612209929/Portfolio%20Pics/Happy-space/HistoryScreen_pvrvvx.png",
    ],
    text: [
      " Happy-Space is my first React Native App store App.",
      "It was built using 5 Async Api calls, 4 screens, and a homepage with some fun animations",
      "My main goal was to get ANYTHING on the app store, but I had a really great time making it and playing with React Native",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1611784258/Portfolio%20Pics/Logos/ReactNativeKnockout_v54jwe.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512571/Portfolio%20Pics/html5-plain-wordmark.67d97895_kehxm9.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512571/Portfolio%20Pics/js-logo_xrxnfl.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512573/Portfolio%20Pics/css-logo_scrgq5.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/postman-logo_rr22mr.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
    ],
    icon: "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1612209953/Portfolio%20Pics/Happy-space/icon_axfldx.png",
    appStore:
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1612209961/Portfolio%20Pics/Happy-space/downloadAppStore_hz55mh.png",
  },
  {
    id: "trinity",
    navUp: "happy-space",
    navDown: "mern",
    github: "https://github.com/rbalonek/trinity-place-shelter",
    title: "Trinity Place Shelter",
    website: "https://trinity-place-shelter.netlify.app/",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1598326354/Portfolio%20Pics/trinityPlaceShelter_srxffl.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/r_22/v1599500206/Portfolio%20Pics/Trinity-cell-2_gx7ytc.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/r_22/v1599500206/Portfolio%20Pics/Trinity-cell-1_e2d9xi.png",
    ],
    text: [
      "Trinity Place Shelter was my first project working with a UX team and multiple developers. We used a MERN stack to complete.",
      "I was the team leader so I controlled the github project flow using their Kanban project board.",
      'We added an admin screen to show full CRUD capabilities with the volunteer form and were asked to make a "Pixel-Perfect" recreation from the UX teams Figma.',
      "While following a Scrum / Agile workflow, we finished our sprint early, and were able to add on a few extra features.",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512571/Portfolio%20Pics/mongodb-plain-wordmark.07c92838_rgtxc3.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1633719287/Portfolio%20Pics/Logos/expressjs-logo-text-serverside-runtime-system_hstig2.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/react-original-wordmark.fadd9c95_fhawii.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512573/Portfolio%20Pics/git-original-wordmark.66e06c06_s7bavw.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/postman-logo_rr22mr.png",
    ],
  },
  {
    id: "mern",
    navUp: "trinity",
    navDown: "leading-out-the-woods",
    github: "https://github.com/rbalonek/mern-bootstrap-project",
    title: "MERN Bootstrap",
    website:
      "https://mern-bootstrap-portfolio.netlify.app/",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1633817067/Portfolio%20Pics/mern1_emjmia.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1633817081/Portfolio%20Pics/mern2_vk7hvc.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1633817075/Portfolio%20Pics/mern3_sppsbn.png",
    ],
    text: [
      "I was practicing my MERN stack for a job interview and put this together",
      "It's a super simple product displaying app with Bootstrap design.",
      "I included a search and a sort-by feature as well as making a mock login for CRUD on the products",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512571/Portfolio%20Pics/mongodb-plain-wordmark.07c92838_rgtxc3.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1633719287/Portfolio%20Pics/Logos/expressjs-logo-text-serverside-runtime-system_hstig2.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/react-original-wordmark.fadd9c95_fhawii.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512573/Portfolio%20Pics/git-original-wordmark.66e06c06_s7bavw.svg",
      // "https://res.cloudinary.com/bobalobbadingdong/image/upload/c_scale,h_50,w_100/v1633719579/Portfolio%20Pics/Logos/Netlify_oliy4v.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/postman-logo_rr22mr.png",
    ],
  },
  
  {
    id: "leading-out-the-woods",
    navUp: "mern",
    navDown: "opera-search",
    github: "https://github.com/rbalonek/",
    title: "Leading out the Woods",
    website: "https://www.leadingoutthewoods.com/",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1633817208/Portfolio%20Pics/WoodsOne_wcn0qs.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1633817196/Portfolio%20Pics/woodsTwo_psxyqm.png",
    ],
    text: [
      "This was a fun project to build out for an author and touring speaker.",
      "I utilized databases for his books, reviews, and touring dates.",
      "Turnaround time was a weekend including edits.",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1662672865/Portfolio%20Pics/Creator_o9nvzl.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1626733192/Portfolio%20Pics/EditorX_qeugvc.jpg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1626733095/Portfolio%20Pics/wix_mooazv.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1626733236/Portfolio%20Pics/4a5235_1df4b8d3f8014934aba113b6e0d1b32e_mv2_holvsh.png",
    ],
  },
  {
    id: "opera-search",
    navUp: "leading-out-the-woods",
    navDown: "signup-template",
    github: "https://github.com/rbalonek/opera-singer-search",
    title: "Opera Singer Search",
    website: "https://opera-singer-search.netlify.app/operas",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604968788/Portfolio%20Pics/Screen_Shot_2020-11-09_at_4.29.23_PM_za1mww.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/r_45/v1600955664/Portfolio%20Pics/singer-search-cell-Operas_dzgw31.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/r_45/v1600955664/Portfolio%20Pics/singer-search-cell-Singers-page_etlfsz.png",
    ],
    text: [
      "A full stack application using a Rails back end and React front end with fully functioning user authentication.",
      "Singers can register / login and add opera roles to their resume so when you click on an opera, the singers name will appear under the 'roles' tab.",
      "Singers can also create, update, and delete blog-style recent performances on their singer page and it will appear on their profile page.",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/react-original-wordmark.fadd9c95_fhawii.svg",
      // "https://res.cloudinary.com/bobalobbadingdong/image/upload/c_scale,h_50,w_100/v1633719579/Portfolio%20Pics/Logos/Netlify_oliy4v.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512573/Portfolio%20Pics/heroku-plain-wordmark.6fd6c3b7_pgrfhc.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/rails-logo_f3zkrq.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512569/Portfolio%20Pics/postgresql-plain-wordmark.06aadd67_jqewef.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/ruby-plain-wordmark.b065b718_pxbhrj.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
    ],
  },
  {
    id: "signup-template",
    navUp: "opera-search",
    navDown: "operabob",
    github: "https://github.com/rbalonek/Signup_Template",
    title: "Vendor Signup",
    website: "https://signup-template.netlify.app/",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1633273697/Portfolio%20Pics/Templates/vendor_v1enfe.jpg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1634228778/Portfolio%20Pics/Templates/Screen_Shot_2021-10-14_at_9.20.23_AM_cfowng.png",
    ],
    text: [
      "I had a client who wanted an app for vendors to signup for a health fair",
      "I used React / Rails and built this prototype to show how the app would work. Also, I hooked up EmailJS to send automated confirmations to the vendors and the organizers.",
      "These can be easily implemented anywhere on a website with one copy and pate into your site.",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/react-original-wordmark.fadd9c95_fhawii.svg",
      // "https://res.cloudinary.com/bobalobbadingdong/image/upload/c_scale,h_50,w_100/v1633719579/Portfolio%20Pics/Logos/Netlify_oliy4v.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512573/Portfolio%20Pics/heroku-plain-wordmark.6fd6c3b7_pgrfhc.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/rails-logo_f3zkrq.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512569/Portfolio%20Pics/postgresql-plain-wordmark.06aadd67_jqewef.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/ruby-plain-wordmark.b065b718_pxbhrj.svg",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
    ],
  },
  {
    id: "operabob",
    navUp: "signup-template",
    navDown: "about",
    github: "https://github.com/rbalonek/new-opera-site",
    title: "Performer Website",
    website: "https://www.robertbalonek.com/",
    carousel: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1634245048/robertbalonek.com/operabob1_juph5a.gif",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1634245048/robertbalonek.com/operabob2_waun2z.gif",
    ],
    text: [
      "This is a fairly simple, responsive opera singer website utilizing a local database for all the information.",
      "I wanted to keep to a basic theme with red, white, and black and subtle animations.",
      "I can easily duplicate this site, and import any performers information here.",
    ],
    stack: [
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512568/Portfolio%20Pics/react-original-wordmark.fadd9c95_fhawii.svg",
      // "https://res.cloudinary.com/bobalobbadingdong/image/upload/c_scale,h_50,w_100/v1633719579/Portfolio%20Pics/Logos/Netlify_oliy4v.png",
      "https://res.cloudinary.com/bobalobbadingdong/image/upload/v1604512570/Portfolio%20Pics/node-js-logo_qxghko.png",
    ],
  },
];

export default Projects;

// {
//   id: "",
//   navUp: "",
//   navDown: "",
//   github: "",
//   title: "",
//   website: "",
//   carousel: [],
//   text: [],
//   stack: [],
// },/
